<script>
  import { createEventDispatcher } from 'svelte';
  import Modal from '@bizneo/modal/modal.svelte';

  export let icon;
  export let confirm;
  export let description;
  export let html;
  export let cancel;
  export let ok;
  export let size = 'small';
  export let i18n = {
    confirmButton: 'Confirmar',
    cancelButton: 'Cancelar',
  };

  const dispatch = createEventDispatcher();

  const getSize = () => (['large', 'medium', 'small'].includes(size) ? `is-${size}` : '');
</script>

<Modal on:close>
  <div class="modal-body {getSize()}">
    <div class="confirm-content">
      {#if icon}
        <img src={icon} alt="Dynamic icon" />
      {:else}
        <div class="modal-illustration ml-auto mr-auto mb-4 is-red">
          <i class="huge-icon huge-alert-02-bulk-rounded is-large text-current"></i>
        </div>
      {/if}
      <p class="h2">{confirm}</p>
      {#if description}
        <p>
          {@html description}
        </p>
      {/if}
      {#if html}
        <div>
          {@html html}
        </div>
      {/if}
    </div>
  </div>
  <div class="modal-footer buttons-row right">
    <button on:click={() => dispatch('close')} class="button secondary">{cancel || i18n.cancelButton}</button>
    <button on:click={() => dispatch('confirm')} class="button primary">{ok || i18n.confirmButton}</button>
  </div>
</Modal>
