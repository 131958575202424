<script>
  import { onMount, createEventDispatcher, tick, afterUpdate } from 'svelte';
  import Icon from '@bizneo/icon/icon.svelte';

  export let closable = true;
  export let content;
  export let scrollTo;
  export let theme = false;
  export let wrapper_class = false;

  let modal;
  let active;

  const dispatch = createEventDispatcher();

  onMount(() => {
    active = true;
  });

  export const getContainer = () => modal;

  export function replace(newContent, opts = {}) {
    theme = opts.theme == undefined ? false : opts.theme;
    wrapper_class = opts.wrapper_class == undefined ? false : opts.wrapper_class;
    closable = opts.closable == undefined ? true : opts.closable;

    // It fails to replace modals with selectors, so we use jquery
    /* content = newContent; */
    jQuery(modal).find('.modal-content').html(newContent);

    console.log('Modal updated');
  }

  export function close() {
    (function fadeOut() {
      if (modal && (modal.style.opacity -= 0.1) < 0) {
        setTimeout(() => {
          dispatch('close');
          console.log('Modal closed');
        }, 0);
      } else {
        requestAnimationFrame(fadeOut);
      }
    })();
  }

  function handleClose({ target }) {
    if (modal.contains(target) && target.classList.contains('modal-close')) close();
  }

  function handleKeyDown({ key }) {
    if (closable && key === 'Escape') close();
  }

  function handleClickOutside(event) {
    if (closable && event.target.classList.contains('modal-viewport')) close();
  }

  async function scrollToElement(node) {
    await tick();
    if (scrollTo) node.querySelector(scrollTo).scrollIntoView();
  }
</script>

<svelte:window on:mousedown={handleClose} on:keydown={handleKeyDown} />

<div bind:this={modal} class="modal {theme ? theme : ''}  {wrapper_class ? wrapper_class : ''}" class:active>
  <div class="modal-backdrop" />
  <div class="modal-viewport" on:mousedown={handleClickOutside}>
    <div class="modal-dialog">
      {#if closable}
        <button type="button" class="close-button" on:click={close}>
          <Icon name="huge-cancel-01" />
        </button>
      {/if}
      <div class="modal-content" use:scrollToElement>
        <slot />
        {#if typeof content === 'string'}
          {@html content}
        {/if}
      </div>
    </div>
  </div>
</div>
